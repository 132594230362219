import React from 'react';
import { Typography, Box } from '@mui/material';

const AAF = () => {
  return (
    <Box>
      <Typography variant="h5">AAF Form</Typography>
    </Box>
  );
};

export default AAF;
