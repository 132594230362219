import React from 'react';
import { Typography, Box } from '@mui/material';

const CCF = () => {
  return (
    <Box>
      <Typography variant="h5">CCF form</Typography>
    </Box>
  );
};

export default CCF;
