import React from 'react';
import { Typography, Box } from '@mui/material';

const OBS = () => {
  return (
    <Box>
      <Typography variant="h5">OBS Form</Typography>
    </Box>
  );
};

export default OBS;
